<template>
  <div class="bg-white rounded">
    <div class="grid grid-cols-1 py-5 px-3 md:grid-cols-2">
      <div>
        <TitlePlus title="Logs" :hide-plus="true" class="pl-2" />
      </div>

      <div class="flex md:justify-end">
        <date-range-picker
          :time-filter-enabled="true"
          @apply-date="onApplyFilterDateRange"
          @cancel-date="onApplyFilterDateRange"
        />
      </div>
    </div>

    <template>
      <FSTable
        :fst-id="fstId"
        :endpoint="getEndpoint"
        :isExportEnabled="false"
        :searchEnabled="false"
      >
        <template #default="{ data }">
          <TimelineGroup v-if="data.length > 0" :logs="data" />
        </template>
      </FSTable>
    </template>
  </div>
</template>

<script>
import { useEndpoints } from '@/composables'
import { FSTable, onApplyFilterDateRange } from '@/components/fs-table'
import TimelineGroup from '@/components/timeline-new-final/TimelineGroup.vue'
import TitlePlus from '@/components/ui/TitlePlus'
export default {
  name: 'ViewOrgUserLogs',

  components: {
    FSTable,
    TimelineGroup,
    TitlePlus,
    DateRangePicker: () =>
      import('@/components/picker/date-range/DateRangePicker'),
  },

  computed: {
    reqId() {
      return this.$route.params.id
    },

    getEndpoint() {
      return useEndpoints.user.logs(this.reqId)
    },
  },
  data() {
    return {
      fstId: 'org-user-logs',
    }
  },
  methods: {
    onApplyFilterDateRange,
  },
}
</script>

<style lang="scss" scoped></style>
